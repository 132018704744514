import { useMemo, useState } from "react";
import {
  DeleteBtnContainer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  ProductDetails,
  StyledDrawer,
  TotalPrice,
} from "./style";
import { useCollection, useCollectionActions } from "../../context/collections";
import { Col, Image, notification } from "antd";
import { ProductType } from "../../types/type";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import RequestModal from "../Request/RequestModal";
import emailjs from "emailjs-com";
import { DeleteFilled } from "@ant-design/icons";
import SecondaryButton from "../Buttons/SecondaryButton";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { transformToHyphenated } from '../../helpers';

const CustomDrawer = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { state } = useCollection();
  const { t } = useTranslation();
  const { toggleDrawer, removeCollection, resetCollection } =
    useCollectionActions();
  const [modalVisible, setModalVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const totalPrice = useMemo(() => {
    return state.items.reduce((acc: number, value: any) => {
      return acc + (parseInt(value.price) || 0);
    }, 0);
  }, [state.items]);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (values: any) => {
    setLoading(true);
    const collectionImages = state.items.reduce((acc: any, value: any, index: number) => {
      acc[`collection_${index + 1}`] = value.medium;
      return acc;
    }, {});

    const emailData: any = {
      fullName: values?.fullName,
      phoneNumber: values?.phoneNumber,
      email: values?.email,
      links: values?.links,
      dateTime: values?.dateTime ? moment(values.dateTime).isValid() ? moment(values.dateTime).format('DD-MM-yyyy') : '' : '',
      description: values?.description,
      ...(values?.link_1 && { link_1: values?.link_1 }),
      ...(values?.link_2 && { link_2: values?.link_2 }),
      ...(values?.link_3 && { link_3: values?.link_3 }),
      ...(values?.link_4 && { link_4: values?.link_4 }),
      ...(values?.link_5 && { link_5: values?.link_5 }),
      ...(values?.link_6 && { link_6: values?.link_6 }),
      ...collectionImages,
    };

    emailjs.send('service_4qnk66m', 'template_u4ota17', emailData, 'YvW3RqHC-ysKQb-aq')
      .then(() => {
        api.success({
          message: `Call request sent successfully`,
          placement: "top",
        });
        setEmailSent(true);
      }, (error) => {
        api.error({
          message: `Email send error: ${error.text}`,
          placement: 'top',
        });
      })
      .finally(() => {
        setLoading(false)
      });
    setModalVisible(false);
  };

  const onProducts = () => {
    toggleDrawer();
    navigate("/shop");
  };

  const onVisitProduct = (technicalDescription: string) => {
    toggleDrawer();
    const transformedTechnicalDescription = transformToHyphenated(technicalDescription);
    navigate(`/products/${transformedTechnicalDescription}`);
  };

  const onCollections = () => {
    toggleDrawer();
    navigate("/collections");
  };

  const handleCreateNewCollection = () => {
    resetCollection();
    setEmailSent(false);
    toggleDrawer();
  };

  const onRemoveCollection = (e: any, id: string) => {
    e.stopPropagation();
    removeCollection(id);
  };

  return (
    <StyledDrawer
      title={
        <DrawerHeader onClick={onCollections}>
          <p>{t('collection.title')}</p>
        </DrawerHeader>
      }
      width={442}
      placement="right"
      closable={false}
      onClose={toggleDrawer}
      open={state.showDrawer}
      key="right"
      footer={
        <DrawerFooter>

          <TotalPrice>
            <p>
              <b>Total Price:{' '}</b>
              € {totalPrice}
            </p>
          </TotalPrice>
          <PrimaryButton
            onClick={handleModalOpen}
            type="primary"
            disabled={emailSent}
          >
            {emailSent ? t('button.request_sent') : t('button.request_call')}
          </PrimaryButton>
          {emailSent && (
            <PrimaryButton onClick={handleCreateNewCollection} type="primary">
              {t('button.create_new_collection')}
            </PrimaryButton>
          )}
          <p onClick={onProducts}>{t('collection.continue_adding_collection')}</p>
        </DrawerFooter>
      }
    >
      {contextHolder}
      <div>
        {state.items.map((pr: ProductType) => (
          <DrawerBody onClick={() => onVisitProduct(pr.technicalDescription as string)}>
            <Col span={6}>
              <Image
                // width={68}
                height={71}
                src={pr.url || pr.medium || "https://placehold.co/  "}
                fallback="https://placehold.co/600"
              />
            </Col>
            <Col span={16}>
              <ProductDetails>
                <p>{pr.name || pr.technicalDescription}</p>
                {/* <p>€ {pr.price}</p> */}
                {/* <p>{pr.subtitle || pr.bANQADescriptionENG}</p> */}
              </ProductDetails>
            </Col>
            <Col span={2}>
              <DeleteBtnContainer>
                <SecondaryButton onClick={(e: any) => onRemoveCollection(e, pr.id as string)} shape='circle' icon={<DeleteFilled />} />
              </DeleteBtnContainer>
            </Col>
          </DrawerBody>
        ))}
        <RequestModal
          visible={modalVisible}
          onCancel={handleModalCancel}
          onFinish={handleFormSubmit}
          loading={loading}
        />
      </div>
    </StyledDrawer>
  );
};

export default CustomDrawer;

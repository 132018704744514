import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Image, Row, notification } from 'antd';
import emailjs from 'emailjs-com';
import { useCollection, useCollectionActions } from '../../context/collections';
import { CarouselContainer, CollectionDrawer, ContentWrapper, DrawerBody, DrawerFooter, DrawerHeader, DrawerWrapper, MaskInfo, MasonryLayout, MobileContainer, MobileDeleteBtnContainer, MobileHeader, MobileProductImage, ProductDetails, ProductImage, ProductImageContainer, ProductImageOverlay, ProductsCategoryContainer, ProductsTitle, ProductsWrapper, StyledCarousel, TotalPrice } from './style';
import { ProductType } from '../../types/type';
// import ViewImageInfo from '../../components/icons/ViewImageInfo';
import HideImageInfo from '../../components/icons/HideImageInfo';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import RequestModal from '../../components/Request/RequestModal';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../../constants';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import { DeleteFilled } from '@ant-design/icons';
import Spinner from '../../components/common/Spinner';
// import { getRandomSize } from '../../helpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { transformToHyphenated } from '../../helpers';
import { SlickArrowLeft, SlickArrowRight } from '../../components/Buttons/CarouselButtons';

function ProdImage({ prod, handleVisitProduct, selectedLanguage }: any) {
  const [isLoading, setIsLoading] = useState(true);
  // const { height } = getRandomSize(0, 0, 200, 400);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = (e: any) => {
    setIsLoading(false);
    e.target.src = DEFAULT_IMAGE_PLACEHOLDER;
  };

  return (
    <>
      {isLoading && <Spinner size="large" />}
      <ProductImage
        src={prod?.url || prod?.highres || DEFAULT_IMAGE_PLACEHOLDER}
        // src={prod.img}
        // style={{ height }}
        onLoad={handleLoad}
        onClick={handleVisitProduct}
        onError={handleError}
        preview={{
          mask: (
            <MaskInfo>
              <h3>
                {prod?.name ||
                  (selectedLanguage === 'ES'
                    ? prod?.technicalDescriptionEs || prod?.technicalDescription
                    : prod?.technicalDescription)
                }
              </h3>
              <p>
                {prod?.subtitle ?? prod?.bANQADescriptionENG}
              </p>
            </MaskInfo>
          )
        }}
      />
    </>
  );
}

function MobileCarouselProdImage({ prod, handleVisitProduct, selectedLanguage, onRemoveCollection }: any) {
  const [isLoading, setIsLoading] = useState(true);
  // const { height } = getRandomSize(0, 0, 200, 400);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = (e: any) => {
    setIsLoading(false);
    e.target.src = DEFAULT_IMAGE_PLACEHOLDER;
  };

  return (
    <MobileProductImage>
      {isLoading && <Spinner size="large" />}
      <MobileDeleteBtnContainer>
        <SecondaryButton onClick={onRemoveCollection} shape='circle' icon={<DeleteFilled />} />
      </MobileDeleteBtnContainer>
      <ProductImage
        src={prod?.url || prod?.highres || DEFAULT_IMAGE_PLACEHOLDER}
        // src={prod.img}
        // style={{ height }}
        onLoad={handleLoad}
        onClick={handleVisitProduct}
        onError={handleError}
        preview={{
          mask: (
            <MaskInfo>
              <h3>
                {prod?.name ||
                  (selectedLanguage === 'ES'
                    ? prod?.technicalDescriptionEs || prod?.technicalDescription
                    : prod?.technicalDescription)
                }
              </h3>
              <p>
                {prod?.subtitle ?? prod?.bANQADescriptionENG}
              </p>
            </MaskInfo>
          )
        }}
      />
    </MobileProductImage>
  );
}

const Collections = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { state } = useCollection();
  const { t, i18n } = useTranslation();
  const { removeCollection, resetCollection } = useCollectionActions();
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [imageData, setImageData] = useState<ProductType[]>(state.items);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const collectionImagesRef = useRef(null);

  const selectedLanguage = useMemo(() => {
    return i18n.language !== 'gb' ? 'ES' : 'GB';
  }, [i18n.language]);

  const totalPrice = useMemo(() => {
    return state.items.reduce((acc: number, value: any) => {
      return acc + (parseInt(value.price) || 0);
    }, 0);
  }, [state.items]);

  const handleShowImageInfo = (id: number | string) => {
    setImageData((prev: any) =>
      prev.map((data: any) =>
        data.id === id ? { ...data, showInfo: !data.showInfo } : data
      )
    );
  };

  const handleVisitProduct = (technicalDescription: string) => {
    const transformedTechnicalDescription = transformToHyphenated(technicalDescription);
    navigate(`/products/${transformedTechnicalDescription}`);
  };

  const onProducts = () => {
    navigate('/shop');
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = (values: any) => {
    setLoading(true);
    const collectionImages = imageData.reduce((acc: any, value: any, index: number) => {
      acc[`collection_${index + 1}`] = value.medium;
      return acc;
    }, {});

    const emailData: any = {
      fullName: values?.fullName,
      email: values?.email,
      phoneNumber: values?.phoneNumber,
      links: values?.links,
      dateTime: values?.dateTime ? moment(values.dateTime).isValid() ? moment(values.dateTime).format('DD-MM-yyyy') : '' : '',
      description: values?.description,
      link_1: values?.link_1,
      link_2: values?.link_2,
      link_3: values?.link_3,
      link_4: values?.link_4,
      link_5: values?.link_5,
      link_6: values?.link_6,
      ...collectionImages
    };

    emailjs.send('service_4qnk66m', 'template_u4ota17', emailData, 'YvW3RqHC-ysKQb-aq')
      .then(() => {
        api.success({
          message: `Request sent successfully`,
          placement: 'top',
        });
        setEmailSent(true);
      }, (error) => {
        api.error({
          message: `Email send error: ${error.text}`,
          placement: 'top',
        });
      }).finally(() => {
        setLoading(false);
      });

    setModalVisible(false);
  };

  const handleCreateNewCollection = () => {
    resetCollection();
    setEmailSent(false);
  };

  const onRemoveCollection = (e: any, id: string) => {
    e.stopPropagation();
    removeCollection(id);
  };

  useEffect(() => {
    setImageData(state.items);
  }, [state.items]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <ContentWrapper ref={collectionImagesRef}>
        {contextHolder}
        <ProductsWrapper>
          <ProductsCategoryContainer>
            <ProductsTitle>{t('collection.title')}</ProductsTitle>
            <p>{t('collection.description_1')}</p>
            <p>{t('collection.description_2')}</p>
          </ProductsCategoryContainer>
          <MasonryLayout>
            {(imageData ?? []).map((pr: any, index: number) => (
              <ProductImageContainer key={pr.id} delay={++index}>
                <ProdImage
                  prod={pr}
                  onClick={() => handleVisitProduct(pr.technicalDescription as string)}
                  selectedLanguage={selectedLanguage}
                />
                <ProductImageOverlay show={pr.showInfo}>
                  <Row className="masked-row">
                    <Col sm={22}>
                      <MaskInfo>
                        <h3>{pr?.name || pr?.technicalDescription}</h3>
                        <p>{pr?.subtitle || pr?.bANQADescriptionENG}</p>
                      </MaskInfo>
                    </Col>
                    <Col sm={2}>
                      <Button
                        shape="circle"
                        icon={<HideImageInfo />}
                        onClick={() => handleShowImageInfo(pr.id)}
                      />
                    </Col>
                  </Row>
                </ProductImageOverlay>
              </ProductImageContainer>
            ))}
          </MasonryLayout>
        </ProductsWrapper>
        <CollectionDrawer height={dimensions.height}>
          <div>
            <DrawerHeader>
              <p>{t('collection.your_collection')}</p>
            </DrawerHeader>
            <DrawerWrapper>
              {state.items.map((pr: any) => (
                <DrawerBody onClick={() => handleVisitProduct(pr.technicalDescription as string)}>
                  <Col span={6}>
                    <Image
                      height={71}
                      src={pr.url || pr.medium || DEFAULT_IMAGE_PLACEHOLDER}
                      fallback={DEFAULT_IMAGE_PLACEHOLDER}
                    />
                  </Col>
                  <Col span={16}>
                    <ProductDetails>
                      <p>{pr.name || pr.technicalDescription}</p>
                      {/* <p>€ {pr.price}</p> */}
                    </ProductDetails>
                  </Col>
                  <Col span={2}>
                    <SecondaryButton onClick={(e: any) => onRemoveCollection(e, pr.id as string)} shape='circle' icon={<DeleteFilled />} />
                  </Col>
                </DrawerBody>
              ))}
            </DrawerWrapper>
          </div>
          <DrawerFooter>
            <TotalPrice>
              <p>
                <b>Total Price:{' '}</b>
                € {totalPrice}
              </p>
            </TotalPrice>
            <PrimaryButton onClick={handleModalOpen} type="primary" disabled={emailSent}>
              {emailSent ? t('button.request_sent') : t('button.request_call')}
            </PrimaryButton>
            {emailSent && (
              <PrimaryButton onClick={handleCreateNewCollection} type="primary">
                {t('button.create_new_collection')}
              </PrimaryButton>
            )}
            <p onClick={onProducts}>{t('collection.continue_adding_collection')}</p>
          </DrawerFooter>
        </CollectionDrawer>
        <RequestModal
          visible={modalVisible}
          onCancel={handleModalCancel}
          onFinish={handleFormSubmit}
          loading={loading}
        />
      </ContentWrapper>
      <MobileContainer>
        <MobileHeader>
          <div>
            <h2>Your Collection</h2>
          </div>
          <div>
            <PrimaryButton onClick={handleModalOpen} type="primary" disabled={emailSent}>
              {emailSent ? t('button.request_sent') : t('button.request_call')}
            </PrimaryButton>
            <p onClick={onProducts}>{t('collection.continue_adding_collection')}</p>
          </div>
        </MobileHeader>
        <p>{t('collection.description_1')}</p>
        <p>{t('collection.description_2')}</p>
        <CarouselContainer>
          <StyledCarousel
            arrows
            className="desktop-carousel"
            prevArrow={<SlickArrowLeft currentSlide={0} slideCount={0} />}
            nextArrow={<SlickArrowRight currentSlide={0} slideCount={imageData?.length} />}
          >
            {(imageData ?? []).map((pr: any, index: number) => (
              <ProductImageContainer key={pr.id} delay={++index}>
                <MobileCarouselProdImage
                  prod={pr}
                  onRemoveCollection={(e: any) => onRemoveCollection(e, pr?.id as string)}
                  onClick={() => handleVisitProduct(pr.technicalDescription as string)}
                  selectedLanguage={selectedLanguage}
                />
                <ProductImageOverlay show={pr.showInfo}>
                  <Row className="masked-row">
                    <Col sm={22}>
                      <MaskInfo>
                        <h3>{pr?.name || pr?.technicalDescription}</h3>
                        <p>{pr?.subtitle || pr?.bANQADescriptionENG}</p>
                      </MaskInfo>
                    </Col>
                    <Col sm={2}>
                      <Button
                        shape="circle"
                        icon={<HideImageInfo />}
                        onClick={() => handleShowImageInfo(pr.id)}
                      />
                    </Col>
                  </Row>
                </ProductImageOverlay>
              </ProductImageContainer>
            ))}
          </StyledCarousel>
        </CarouselContainer>
        <div>
          {(imageData ?? []).map((pr: any, index: number) => (
            <ProductImageContainer key={pr.id} delay={++index}>
              <ProdImage
                prod={pr}
                onClick={() => handleVisitProduct(pr.technicalDescription as string)}
                selectedLanguage={selectedLanguage}
              />
              <ProductImageOverlay show={pr.showInfo}>
                <Row className="masked-row">
                  <Col sm={22}>
                    <MaskInfo>
                      <h3>{pr?.name || pr?.technicalDescription}</h3>
                      <p>{pr?.subtitle || pr?.bANQADescriptionENG}</p>
                    </MaskInfo>
                  </Col>
                  <Col sm={2}>
                    <Button
                      shape="circle"
                      icon={<HideImageInfo />}
                      onClick={() => handleShowImageInfo(pr.id)}
                    />
                  </Col>
                </Row>
              </ProductImageOverlay>
            </ProductImageContainer>
          ))}
        </div>
      </MobileContainer>
    </>
  )
}

export default Collections
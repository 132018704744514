import { collection, getDocs, query } from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../../firebase/config";
import { ProductType } from '../../types/type';
import { useProducts } from '../../store/useProducts';
import { transformString } from '../../helpers';

type useGetAllProductsType = {
  data: ProductType[]
  ids: string[]
  fetchData: () => void,
  loading: boolean
}

export default function useGetAllProducts(): useGetAllProductsType {
  const { setProducts: setProductsStore } = useProducts();
  const [products, setProducts] = useState<ProductType[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(() => {
    async function fetchData() {
      setLoading(true);
      const docRef = query(collection(db, "products"));

      await getDocs(docRef)
        .then((docSnap) => {
          const databaseInfo: any[] = [];
          const dataIds: string[] = [];

          docSnap.forEach(async (doc) => {
            databaseInfo.push({
              ...doc.data(),
              id: doc.id,
              type: 'product'
            });
            dataIds.push(doc.id);
          });

          setIds(dataIds);
          setProducts(databaseInfo);
          setProductsStore(databaseInfo);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);

  return {
    data: products,
    ids,
    fetchData: refetch,
    loading,
  };
}

export function useGetProduct(): any {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const refetch = useCallback((technicalDescription: string) => {
    async function fetchData() {
      setLoading(true);
      try {
        const q = query(collection(db, 'products'));
        const querySnapshot = await getDocs(q);
        const products = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));

        const matchingProduct = products.find((product: any) => {
          const productDescription = product?.technicalDescription;
          const normalizedProductDescription = transformString(productDescription?.toLowerCase());
          return normalizedProductDescription.toLowerCase() === technicalDescription?.toLowerCase();
        });

        if (matchingProduct) {
          setUser(matchingProduct);
          return matchingProduct;
        } else {
          console.log('No matching product found.');
          return null;
        }
      } catch (error) {
        console.error('useGetProductError: ', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return {
    data: user,
    fetchData: refetch,
    loading,
  };
}

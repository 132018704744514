import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { HamburgerButtonContainerProps } from './types';

export const Container = styled.div`
  height: 6.125rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${theme.bg.primary};

  @media (max-width: 770px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  position: fixed;
  z-index: 99;
  background-color: ${theme.bg.primary};
  padding: 1.25rem;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: translateX(0);
  }

  > .logo-wrapper {
    display: flex;
    gap: 3.5rem;

    .ant-btn-icon {
      span {
        color: white;
        font-size: 40px;
      }
    }
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.625rem;
  a {
    color: white;
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 200;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
      transition: 0.4s;
    }

    @media (max-width: 1100px) {
      font-size: 0.75rem;
    }
  }

  .translation-wrapper {
    display: flex;
    gap: 1rem;
    cursor: pointer;
  }
`;

export const LogoContainer = styled.div`
  height: 32px;
  // width: 14.563rem;
  width: 161px;
`;

export const Logo = styled.img`
  object-fit: contain;
  // aspect-ratio: 768 / 163;
  width: inherit;
  // height: auto;
`;

export const HamburgerButtonContainer = styled.div<HamburgerButtonContainerProps>`
  display: ${(props) => props.ishidden ? 'none' : 'flex'};
  background: white;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #1e2d4680;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0.625rem;
  left: 0.625rem;
  z-index: 999;

  @media (min-width: 770px) {
    display: none;
  }

  > button {
    padding: 0 !important;
  }
`;

export const DesktopCollectionButton = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const MobileCollectionButton = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';

export const getRandomValue = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const checkLink = async (url: string) => {
  try {
    const response = await fetch(url, { method: 'HEAD' });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
    }

    return true;
  } catch (error) {
    console.error('Error checking link:', error);
    return false;
  }
};

export const getRandomSize = (minWidth: number, maxWidth: number, minHeight: number, maxHeight: number) => {
  const randomWidth = Math.floor(Math.random() * (maxWidth - minWidth + 1) + minWidth);
  const randomHeight = Math.floor(Math.random() * (maxHeight - minHeight + 1) + minHeight);
  return { width: randomWidth, height: randomHeight };
}

export const transformString = (input: string) => {
  if (!input) return ''; // Handle empty or undefined input

  // Split the string by hyphen
  const words = input.split('-');

  // Capitalize the first letter of each word except certain keywords
  const transformedWords = words.map((word) => {
    if (['oak', 'veneer'].includes(word)) {
      return word; // Leave these words lowercase
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the transformed words with spaces
  return transformedWords.join(' ');
};

export const transformToHyphenated = (input: string) => {
  // Convert the string to lowercase and replace spaces with hyphens
  return input.toLowerCase().replace(/\s+/g, '-');
}

const fetchProductSlugs = async () => {
  const productsCollection = collection(db, 'products');
  const productsSnapshot = await getDocs(productsCollection);
  const products = productsSnapshot.docs.map(doc => ({
    name: doc.data().technicalDescription,
  }));

  return products;
};

export const generateSitemap = async () => {
  const products = await fetchProductSlugs();

  const sitemapHeader = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;
  const sitemapFooter = `</urlset>`;

  const baseUrl = 'https://shop.banqa.es';

  const productUrls = products.map((product) => {
    return `
      <url>
        <loc>${baseUrl}/products/${transformToHyphenated(product.name)}</loc>
        <changefreq>daily</changefreq>
        <priority>0.8</priority>
      </url>`;
  }).join('');

  return `${sitemapHeader}${productUrls}\n${sitemapFooter}`;
};

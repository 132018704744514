import React from 'react';
// import { Row, Col } from 'antd';
import { DividerWrapper, FooterFlexWrapper, FooterSection, FooterWidget, MobileFlexWrapper, StyledDivider } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import banqaFooterImg from '../../assets/images/banqa_footer.jpg';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterSection>
      <FooterFlexWrapper>
        <table style={{ width: '100%' }}>
          <thead>
            <FooterWidget className="header">
              <th><h2>{t('footer.openingHours')}</h2></th>
              <th><h2>{t('footer.contactUs')}</h2></th>
              <th></th>
            </FooterWidget>
          </thead>
          <tbody style={{ verticalAlign: 'top', marginTop: '2rem' }}>
            <FooterWidget>
              <td className="tbody-td">
                <h2>OPENING HOURS</h2>
                <table className="table-schedule" style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>{t('footer.monday')}</td>
                      <td>{t('footer.closed')}</td>
                    </tr>
                    <tr>
                      <td>{t('footer.tuesday')}</td>
                      <td>10:00 – 14:00 & 15:30 – 18:30</td>
                    </tr>
                    <tr>
                      <td>{t('footer.wednesday')}</td>
                      <td>10:00 – 14:00 & 15:30 – 18:30</td>
                    </tr>
                    <tr>
                      <td>{t('footer.thursday')}</td>
                      <td>10:00 – 14:00 & 15:30 – 18:30</td>
                    </tr>
                    <tr>
                      <td>{t('footer.friday')}</td>
                      <td>10:00 – 14:00 & 15:30 – 18:30</td>
                    </tr>
                    <tr>
                      <td>{t('footer.saturday')}</td>
                      <td>11:00 – 14:00</td>
                    </tr>
                    <tr>
                      <td>{t('footer.sunday')}</td>
                      <td>{t('footer.closed')}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="tbody-td">
                <h2>CONTACT US</h2>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faLocationDot} />
                    Carrer de Paris 138 bis, 08036
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPhoneFlip} />
                    <a href="tel:+34667361833">+34 667 36 18 33</a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <a href="mailto:info@banqa.es">info@banqa.es</a>
                  </li>
                </ul>
                <h2 className="follow-us">{t('footer.followUs')}</h2>
                <div className="socials">
                  <a href="https://www.instagram.com/banqabarcelona/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} fontSize={25} />
                  </a>
                  <a href="https://www.facebook.com/BANQABARCELONA/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} fontSize={25} />
                  </a>
                  <a href="https://za.pinterest.com/banqabarcelona/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faPinterest} fontSize={25} />
                  </a>
                </div>
                <div className="open-appointments">
                  <p>FROM 18:30 - 20:00 WE ARE OPEN FOR APPOINTMENTS</p>
                </div>
              </td>
              <td className="tbody-td">
              </td>
            </FooterWidget>
          </tbody>
        </table>
        <MobileFlexWrapper>
          <div className="contact-us">
            <h2>{t('footer.contactUs')}</h2>
            <ul>
              <li>
                <FontAwesomeIcon icon={faLocationDot} />
                Carrer de Paris 138 bis, 08036
              </li>
              <li>
                <FontAwesomeIcon icon={faPhoneFlip} />
                <a href="tel:+34667361833">+34 667 36 18 33</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:info@banqa.es">info@banqa.es</a>
              </li>
            </ul>
          </div>
          <div className="follow-us">
            <h2 className="follow-us">{t('footer.followUs')}</h2>
            <div className="socials">
              <a href="https://www.instagram.com/banqabarcelona/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} fontSize={25} />
              </a>
              <a href="https://www.facebook.com/BANQABARCELONA/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} fontSize={25} />
              </a>
              <a href="https://za.pinterest.com/banqabarcelona/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faPinterest} fontSize={25} />
              </a>
            </div>
          </div>
          <div className="opening-hours">
            <h2>{t('footer.openingHours')}</h2>
            <div className="schedule-appointments">
              <div className="schedule-item">
                <p className="day">{t('footer.monday')}: <strong>{t('footer.closed')}</strong></p>
              </div>
              <div className="schedule-item">
                <p className="day">{t('footer.tuesday')}: <strong>10:00 – 14:00 & 15:30 – 18:30</strong></p>
              </div>
              <div className="schedule-item">
                <p className="day">{t('footer.wednesday')}: <strong>10:00 – 14:00 & 15:30 – 18:30</strong></p>
              </div>
              <div className="schedule-item">
                <p className="day">{t('footer.thursday')}: <strong>10:00 – 14:00 & 15:30 – 18:30</strong></p>
              </div>
              <div className="schedule-item">
                <p className="day">{t('footer.friday')}: <strong>10:00 – 14:00 & 15:30 – 18:30</strong></p>
              </div>
              <div className="schedule-item">
                <p className="day">{t('footer.saturday')}: <strong>11:00 – 14:00</strong></p>
              </div>
              <div className="schedule-item">
                <p className="day">{t('footer.sunday')}: <strong>{t('footer.closed')}</strong></p>
              </div>
            </div>
            <div className="open-appointments">
              <p>FROM 18:30 - 20:00 WE ARE OPEN FOR APPOINTMENTS</p>
            </div>
          </div>
        </MobileFlexWrapper>
        <div className="img-footer">
          <img src={banqaFooterImg} alt="BANQA tienda de muebles barcelona" />
        </div>
      </FooterFlexWrapper>
      <DividerWrapper>
        <StyledDivider />
      </DividerWrapper>
    </FooterSection>
  );
};

export default Footer;

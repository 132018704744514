import { Drawer, Row } from 'antd';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  padding: 0;
  .ant-drawer-header {
    padding: 0;
  }

  .ant-drawer-body {
    padding: 40px;
  }
`;

export const DrawerHeader = styled.div`
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #C1A050;
  cursor: pointer;

  p {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    font-family: Raleway;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const DrawerBody = styled(Row)`
  cursor: pointer;
  margin-top: 1.5rem;
`;

export const ProductWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const ProductDetails = styled.div`
  position: relative;
  font-family: Raleway;
  font-size: 18px;
  p:nth-child(1) {
    margin: 0;
    font-weight: 600;
  }

  p:nth-child(2) {
    font-weight: 400;
  }

  div {
    position: absolute;
    top: -10px;
    right: 0;
  }
`;

export const DrawerFooter = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  button {
    width: 100%;
  }
  > p {
    font-weight: 400;
    font-size: 18px;
    font-family: Raleway;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DeleteBtnContainer = styled.div`
  position: relative;
`;

export const TotalPrice = styled.div`
  display: flex;
  justify-content: flex-start;

  > p {
    font-family: Raleway;
    font-size: 1.25rem;
  }
`;
